<template>
  <div id="detail-point">
    <div id="detail-point">
      <AppContainer :useBackground="true" :useShadow="true">
        <card-header ref="header" :node="headerTitle" />
        <div id="content-scroll" class="content-scroll">
          <div class="detail-point--content" v-if="details_point">
            <div class="detail-point--content__div--top">
              <div class="member d-flex justify-content-start">
                <div class="position-relative system-avatar__div">
                  <div
                    class="system-avatar d-flex align-items-center justify-content-center"
                  >
                    <img
                      class="system-avatar__default"
                      src="@/assets/image/bird.svg"
                    />
                  </div>
                  <div class="position-absolute blur-filter">
                    <div class="blur-filter__div"></div>
                  </div>
                </div>
                <div
                  class="member__div d-flex justify-content-start align-items-center flex-column"
                  v-for="(user, index) in details_point.applicants"
                  :key="index"
                >
                  <img :src="user.image_url" />
                  <p class="member__p">
                    <span class="member__a f-w3">{{ user.nickname }}</span>
                  </p>
                </div>
              </div>
              <div class="point">
                <div class="d-flex justify-content-between">
                  <p class="f-w3 category">日付</p>
                  <p class="f-w6 info text-right">
                    {{
                      $dayjs(details_point.scheduled_meeting_datetime).format(
                        "YYYY/MM/DD"
                      )
                    }}
                  </p>
                </div>
                <div class="d-flex justify-content-between">
                  <p class="f-w3 category">ゴルフ場名</p>
                  <p class="f-w6 info text-right">
                    {{ details_point.golf_course }}
                  </p>
                </div>
                <div class="d-flex justify-content-between">
                  <p class="f-w3 category">プラン</p>
                  <p class="f-w6 info text-right">
                    {{ details_point.plan_name }}
                  </p>
                </div>
              </div>
            </div>
            <div class="detail-point--content__div--bottom">
              <div class="d-flex justify-content-between align-items-center">
                <p class="f-w6">合計</p>
                <p class="f-w6">
                  {{
                    details_point.point_amount >= 10000
                      ? Intl.NumberFormat().format(details_point.point_amount)
                      : details_point.point_amount
                  }}P
                </p>
              </div>
            </div>
          </div>
          <div class="empty" v-if="checkDataNull">
            <img src="@/assets/image/empty_data.svg" alt="" class="" />
            <p class="f-w3">獲得ポイントはありません</p>
          </div>
        </div>
        <card-footer ref="footer" />
      </AppContainer>
    </div>
  </div>
</template>

<script>
import CardHeader from "@/components/CardHeader.vue";
import CardFooter from "@/components/CardFooter.vue";

export default {
  name: "DetailPoint",
  components: {
    "card-header": CardHeader,
    "card-footer": CardFooter
  },
  metaInfo() {
    return {
      title: "支払いの詳細",
      titleTemplate: "%s | PreGo"
    };
  },
  async created() {
    let detail = document.getElementById("detail-point");
    this.$root.$refs.loading.start();
    await this.getDetailsPoint();
    this.$root.$refs.loading.finish();
    if (detail) {
      detail.scrollIntoView(true);
    }
  },
  data() {
    return {
      headerTitle: {
        text: "支払いの詳細",
        isShowTitle: true,
        isShowLogo: false
      },
      details_point: null,
      checkDataNull: false
    };
  },
  methods: {
    buyPoint() {
      this.$router.push({ name: "BuyPoint" });
    },
    async getDetailsPoint() {
      await this.$store
        .dispatch("pointHistory/getDetailsPoint", this.$route.params.id)
        .then(res => {
          if (res.data.length === 0) {
            this.checkDataNull = true;
          } else {
            this.details_point = res.data;
            this.checkDataNull = false;
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/detailPoint.scss";
@import "@/assets/scss/_fontFamily.scss";

.content-scroll {
  // overflow-y: scroll;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow-x: hidden;
  // height: calc(100vh - 108px);
}

@media screen and (min-width: 1200px) {
  // .content-scroll {
  //   height: calc(100vh - 152px);
  // }
}
</style>
